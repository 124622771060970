<template>
    <ul role="list" class="relative z-0 divide-y divide-gray-200">
        <li v-for="(item, i) in items" :key="i" class="bg-white">
            <router-link :to="{ name: editRouteName, params: { id: item.id } }"
                         class="relative px-4 py-3 flex items-center space-x-3 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500">
                <div v-if="item.label || item.number"
                     class="flex-shrink-0 h-12 w-12 flex justify-center font-bold items-center text-gray-500">
                    <template v-if="item.label">
                        {{ item.label }}
                    </template>
                    <template v-if="item.number">
                        {{ item.number }}
                    </template>
                </div>
                <div class="flex-1 min-w-0 h-12 flex items-center">
                    <a href="#" class="focus:outline-none">
                        <span class="absolute inset-0" aria-hidden="true" />
                        <p class="text-sm font-medium text-gray-700">
                            {{ item.name_de }}
                        </p>
                    </a>
                </div>
            </router-link>
        </li>
    </ul>
</template>
<script>
export default {
    name: 'List',
    props: {
        items: Array,
        editRouteName: String,
    }
}
</script>