import {
    createFirebaseListener,
    allergensCollection,
    ingredientsCollection,
    productCategoriesCollection,
    productsCollection,
    db,
} from '@/plugins/firebase';

import {collection, addDoc, setDoc, doc} from 'firebase/firestore';

export default {
    namespaced: true,
    state: {
        allergens: [],
        categories: [],
        ingredients_subject_to_labeling: [],
        listeners: [],
        offers: [],
        products: [],
    },
    mutations: {
        addListeners(state, listener) {
            state.listeners.push(listener);
        },
        setAllergens(state, allergens) {
            state.allergens = allergens;
        },
        setIngredientsSubjectToLabeling(state, ingredients) {
            state.ingredients_subject_to_labeling = ingredients;
        },
        setCategories(state, categories) {
            state.categories = categories;
        },
        setProducts(state, products) {
            state.products = products;
        },
    },
    actions: {
        // adds
        async addAllergen({commit}, category) {
            const docRef = await addDoc(collection(db, 'allergens'), category);
            console.log('Allergen written with ID: ', docRef.id);
            return {status: 200, id: docRef.id};
        },
        async addProduct({commit}, category) {
            const docRef = await addDoc(collection(db, 'products'), category);
            console.log('Product written with ID: ', docRef.id);
            return {status: 200, id: docRef.id};
        },
        async addCategory({commit}, category) {
            const docRef = await addDoc(collection(db, 'product_categories'), category);
            console.log('Category written with ID: ', docRef.id);
            return {status: 200, id: docRef.id};
        },
        async addIngredientSubjectToLabeling({commit}, ingredientSubjectToLabeling) {
            const docRef = await addDoc(collection(db, 'ingredients_subject_to_labeling'), ingredientSubjectToLabeling);
            console.log('Ingredient Subject to labeling written with ID: ', docRef.id);
            return {status: 200, id: docRef.id};
        },
        // edits
        async editProduct({commit}, {id, product}) {
            await setDoc(doc(db, 'products', id), product);
            return {status: 200};
        },
        async editAllergen({commit}, {id, allergen}) {
            await setDoc(doc(db, 'allergens', id), allergen);
            return {status: 200};
        },
        async editCategory({commit}, {id, category}) {
            await setDoc(doc(db, 'product_categories', id), category);
            return {status: 200};
        },
        async editIngredientSubjectToLabeling({commit}, {id, ingredient}) {
            await setDoc(doc(db, 'ingredients_subject_to_labeling', id), ingredient);
            return {status: 200};
        },
        // listeners
        listenAllergens({commit}) {
            const callback = (items) => {
                commit('setAllergens', items);
            };
            const listener = createFirebaseListener(allergensCollection, callback);
            commit('addListeners', listener);
        },
        listenProducts({commit}) {
            const callback = (items) => {
                commit('setProducts', items);
            };
            const listener = createFirebaseListener(productsCollection, callback);
            commit('addListeners', listener);
        },
        listenCategories({commit}) {
            const callback = (items) => {
                commit('setCategories', items);
            };
            const listener = createFirebaseListener(productCategoriesCollection, callback);
            commit('addListeners', listener);
        },
        listenIngredientsSubjectToLabeling({commit}) {
            const callback = (items) => {
                commit('setIngredientsSubjectToLabeling', items);
            };
            const listener = createFirebaseListener(ingredientsCollection, callback);
            commit('addListeners', listener);
        },
    },
    getters: {
        getProducts: (state) => () => {
            const products = JSON.parse(JSON.stringify(state.products));
            const sort = (a, b) => a.number-b.number;
            return products.sort(sort);
        },
        getProductById: (state) => (id) => {
            return state.products.filter(item => item.id === id)[0];
        },
        getProductsByCategoryId: (state) => (id) => {
            const products = JSON.parse(JSON.stringify(state.products));
            const sort = (a, b) => a.number-b.number;
            return products.filter(item => item.category === id).sort(sort);
        },
        getEnclosingProducts: (state) => (id) => {
            const sort = (a, b) => a.number-b.number;
            const products = JSON.parse(JSON.stringify(state.products.sort(sort)));
            const index = products.findIndex(item => item.id === id);
            const prev = products[index - 1];
            const next = products[index + 1];
            return { prev, next }
        },

        getCategories: (state) => () => {
            const categories = JSON.parse(JSON.stringify(state.categories));
            const sort = (a, b) => a.name_de-b.name_de;
            return categories.sort(sort);
        },
        getCategoryById: (state, getters) => (id) => {
            const categories = getters.getCategories();
            return categories.filter(item => item.id === id)[0];
        },
        getEnclosingProductCategories: (state, getters) => (id) => {
            const sort = (a, b) => a.name_de-b.name_de;
            const categories = getters.getCategories();
            const index = categories.findIndex(item => item.id === id);
            const prev = categories[index - 1];
            const next = categories[index + 1];
            return { prev, next }
        },

        getIngredientsSubjectToLabeling: (state) => () => {
            const ingredientsSubjectToLabeling = JSON.parse(JSON.stringify(state.ingredients_subject_to_labeling));
            const sort = (a, b) => a.label.localeCompare(b.label, 'de', {numeric: true});
            return ingredientsSubjectToLabeling.sort(sort);
        },
        getIngredientById: (state) => (id) => {
            return state.ingredients_subject_to_labeling.filter(item => item.id === id)[0];
        },
        getEnclosingIngredientsSubjectToLabelingCategories: (state) => (id) => {
            const sort = (a, b) => a.label.localeCompare(b.label, 'de', {numeric: true});
            const ingredientsSubjectToLabeling = JSON.parse(JSON.stringify(state.ingredients_subject_to_labeling.sort(sort)));
            const index = ingredientsSubjectToLabeling.findIndex(item => item.id === id);
            const prev = ingredientsSubjectToLabeling[index - 1];
            const next = ingredientsSubjectToLabeling[index + 1];
            return { prev, next }
        },

        getAllergens: (state) => () => {
            const allergens = JSON.parse(JSON.stringify(state.allergens));
            const sort = (a, b) => {
                if (a.name_de > b.name_de) {
                    return 1;
                }
                if (b.name_de > a.name_de) {
                    return -1;
                }
                return 0;
            };
            return allergens.sort(sort);
        },
        getAllergenById: (state, getters) => (id) => {
            const allergens = getters.getAllergens();
            return allergens.filter(item => item.id === id)[0];
        },
        getEnclosingAllergens: (state, getters) => (id) => {
            const allergens = getters.getAllergens();
            const index = allergens.findIndex(item => item.id === id);
            const prev = allergens[index - 1];
            const next = allergens[index + 1];
            return { prev, next }
        },
    },
};
