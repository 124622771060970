import {createFirebaseListener, db, menusCollection} from '@/plugins/firebase';

import {addDoc, doc, setDoc} from 'firebase/firestore';

export default {
    namespaced: true,
    state: {
        listeners: [],
        menus: [],
    },
    mutations: {
        addListeners(state, listener) {
            state.listeners.push(listener);
        },
        setMenus(state, menus) {
            state.menus = menus;
        },
    },
    actions: {
        // adds
        async addMenu({commit}, menu) {
            const docRef = await addDoc(menusCollection, menu);
            console.log('Menu added - ID: ', docRef.id);
            return {status: 200, id: docRef.id};
        },
        // edits
        async editMenu({commit}, {id, menu}) {
            await setDoc(doc(db, 'menus', id), menu);
            return {status: 200};
        },
        // listeners
        listenMenus({commit}) {
            const callback = (items) => {
                commit('setMenus', items);
            };
            const listener = createFirebaseListener(menusCollection, callback);
            commit('addListeners', listener);
        },
    },
    getters: {
        getMenus: (state) => () => {
            return JSON.parse(JSON.stringify(state.menus));
        },
        getMenuById: (state, getters) => (id) => {
            const menus = getters.getMenus();
            return menus.filter(item => item.id === id)[0];
        },
    },
};
