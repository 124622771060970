<template>
    <button v-if="!path"
            type="submit"
            :disabled="disabled"
            class="button"
            :class="classes">
        <slot></slot>
    </button>
    <router-link v-else
                 :to="path"
                 :disabled="disabled"
                 class="button"
                 :class="classes">
        <slot></slot>
    </router-link>
</template>

<script>
export default {
    name: 'CButton',
    props: {
        path: Object,
        disabled: Boolean,
        variant: String,
    },
    computed: {
        classes() {
            return {
                [`button--${this.variant}`]: this.variant,
            };
        },
    },
};
</script>