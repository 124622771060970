<template>
    <div class="fixed top-0 right-0 bottom-0 left-0 z-50 flex">
        <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 bg-white">
            <div class="mx-auto w-full max-w-sm lg:w-96">
                <div>
                    <img class="h-48 w-auto mr-auto ml-auto mb-10" src="@/assets/logo-full-color.svg" alt="Workflow" />
                    <h2 class="mt-6 text-3xl font-extrabold text-gray-900">
                        Login
                    </h2>
                </div>

                <div>
                    {{ error }}
                </div>

                <div class="mt-8">
                    <div class="mt-6">
                        <form action="#" method="POST" class="space-y-6" @submit.prevent="submit">
                            <div>
                                <label for="email" class="block text-sm font-medium text-gray-700">
                                    E-Mail
                                </label>
                                <div class="mt-1">
                                    <input id="email" name="email" type="email" autocomplete="email" required="" v-model="form.email" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-mauros-green-500 focus:border-mauros-green-500 sm:text-sm" />
                                </div>
                            </div>

                            <div class="space-y-1">
                                <label for="password" class="block text-sm font-medium text-gray-700">
                                    Passwort
                                </label>
                                <div class="mt-1">
                                    <input id="password" name="password" type="password" autocomplete="current-password" required="" v-model="form.password" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-mauros-green-500 focus:border-mauros-green-500 sm:text-sm" />
                                </div>
                            </div>

                            <div class="flex items-center justify-between">
                                <div class="flex items-center">
                                    <input id="remember-me" name="remember-me" type="checkbox" class="h-4 w-4 text-mauros-green-600 focus:ring-mauros-green-500 border-gray-300 rounded" />
                                    <label for="remember-me" class="ml-2 block text-sm text-gray-900">
                                        Angemeldet bleiben
                                    </label>
                                </div>

                                <div class="text-sm">
                                    <a href="#" class="font-medium text-mauros-green-600 hover:text-mauros-green-500">
                                        Passwort vergessen?
                                    </a>
                                </div>
                            </div>

                            <div>
                                <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-mauros-green-600 hover:bg-mauros-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mauros-green-500">
                                    Anmelden
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="hidden lg:block relative w-0 flex-1">
            <img class="absolute inset-0 h-full w-full object-cover" src="@/assets/bg-login.jpg" alt="" />
        </div>
    </div>
</template>
<script>
// import * as  from 'firebase/auth';

import {mapGetters} from 'vuex';
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth';

export default {
    name: 'Login',
    data() {
        return {
            form: {
                email: '',
                password: '',
            },
            error: null,
        };
    },
    methods: {
        submit() {
            const auth = getAuth();
            signInWithEmailAndPassword(auth, this.form.email, this.form.password)
                .then(data => {
                    console.log(data, 'yay');
                })
                .catch(err => {
                    this.error = err.message;
                });
        }
    },
};
</script>
