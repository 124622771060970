import {createApp} from 'vue';
import App from '@/App.vue';
import '@/registerServiceWorker';
import router from '@/router';
import '@/assets/tailwind.css';
import store from '@/store';

import {onAuthStateChanged} from 'firebase/auth';
import {auth} from '@/plugins/firebase';

import CButton from '@/components/CButton';
import PageHead from '@/components/PageHead';
import List from '@/components/List';

onAuthStateChanged(auth, async (user) => {
    await store.dispatch('fetchUser', user);
});

const application = createApp(App);

application
    .use(store)
    .use(router)
    // global components
    .component('PageHead', PageHead)
    .component('CButton', CButton)
    .component('List', List)
    .mount('#app');
