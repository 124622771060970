<template>
    <div class="bg-white" :class="isSticky">
        <div class="bg-white px-4 py-5 border-b border-gray-200 z-10">
            <div class="flex items-center flex-wrap flex-nowrap">
                <slot name="prefix"></slot>
                <div class="flex items-center overflow-hidden">
                    <h3 class="truncate text-lg leading-6 font-medium text-gray-900">
                        {{ title }}
                    </h3>
                </div>
                <div class="ml-auto flex-shrink-0">
                    <slot name="actions"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageHead',
    props: {
        title: String,
        sticky: Boolean,
    },
    computed: {
        isSticky() {
            return {
                ['sticky top-0 z-20']: this.sticky,
            }
        },
    },
};
</script>