<template>
    <div class="h-full flex">
        <template v-if="!user.loggedIn">
            <Login />
        </template>
        <template v-else>
            <!-- Narrow sidebar -->
            <div class="sidebar hidden w-28 bg-gray-700 overflow-y-auto md:block">
                <div class="w-full py-6 flex flex-col items-center">
                    <div class="flex-shrink-0 flex items-center">
                        <img class="h-8 w-auto" src="@/assets/logo-simple-white.svg" alt="Workflow" />
                    </div>
                    <div class="flex-1 mt-6 w-full px-2 space-y-1">
                        <router-link v-for="item in sidebarNavigation" :key="item.name" :to="item.to" :class="[item.current ? 'bg-gray-800 text-white' : 'text-gray-100 hover:bg-gray-800 hover:text-white', 'group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium']" :aria-current="item.current ? 'page' : undefined">
                            <component :is="item.icon" :class="[item.current ? 'text-white' : 'text-gray-300 group-hover:text-white', 'h-6 w-6']" aria-hidden="true" />
                            <span class="mt-2 text-center">{{ item.name }}</span>
                        </router-link>
                    </div>
                </div>
            </div>

            <!-- Mobile menu -->
            <TransitionRoot as="template" :show="mobileMenuOpen">
                <Dialog as="div" class="md:hidden" @close="mobileMenuOpen = false">
                    <div class="fixed inset-0 z-50 flex">
                        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
                            <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
                        </TransitionChild>
                        <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
                            <div class="relative max-w-xs w-full bg-gray-700 pt-5 pb-4 flex-1 flex flex-col">
                                <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                                    <div class="absolute top-1 right-0 -mr-14 p-1">
                                        <button type="button" class="h-12 w-12 rounded-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-white" @click="mobileMenuOpen = false">
                                            <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                                            <span class="sr-only">Close sidebar</span>
                                        </button>
                                    </div>
                                </TransitionChild>
                                <div class="flex-shrink-0 px-4 flex items-center">
                                    <img class="h-8 w-auto" src="@/assets/logo-simple-white.svg" alt="Workflow" />
                                </div>
                                <div class="mt-5 flex-1 h-0 px-2 overflow-y-auto">
                                    <nav class="h-full flex flex-col">
                                        <div class="space-y-1">
                                            <router-link v-for="item in sidebarNavigation" :key="item.name" :to="item.to" :class="[item.current ? 'bg-gray-800 text-white' : 'text-gray-100 hover:bg-gray-800 hover:text-white', 'group py-2 px-3 rounded-md flex items-center text-sm font-medium']" :aria-current="item.current ? 'page' : undefined">
                                                <component :is="item.icon" :class="[item.current ? 'text-white' : 'text-gray-300 group-hover:text-white', 'mr-3 h-6 w-6']" aria-hidden="true" />
                                                <span>{{ item.name }}</span>
                                            </router-link>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </TransitionChild>
                        <div class="flex-shrink-0 w-14" aria-hidden="true">
                            <!-- Dummy element to force sidebar to shrink to fit close icon -->
                        </div>
                    </div>
                </Dialog>
            </TransitionRoot>

            <!-- Content area -->
            <div class="flex-1 flex flex-col overflow-hidden">
                <header class="topbar w-full">
                    <div class="relative z-50 flex-shrink-0 h-16 bg-white border-b border-gray-200 shadow-sm flex">
                        <button type="button" class="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500 md:hidden" @click="mobileMenuOpen = true">
                            <span class="sr-only">Open sidebar</span>
                            <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
                        </button>
                        <div class="flex-1 flex justify-between px-4 sm:px-6">
                            <div class="flex-1 flex">
                                <!--                            <form class="w-full flex md:ml-0" action="#" method="GET">-->
                                <!--                                <label for="search-field" class="sr-only">Search all files</label>-->
                                <!--                                <div class="relative w-full text-gray-400 focus-within:text-gray-600">-->
                                <!--                                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center">-->
                                <!--                                        <SearchIcon class="flex-shrink-0 h-5 w-5" aria-hidden="true" />-->
                                <!--                                    </div>-->
                                <!--                                    <input name="search-field" id="search-field" class="h-full w-full border-transparent py-2 pl-8 pr-3 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400" placeholder="Search" type="search" />-->
                                <!--                                </div>-->
                                <!--                            </form>-->
                            </div>
                            <div class="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
                                <!-- Profile dropdown -->
                                <Menu as="div" class="relative flex-shrink-0">
                                    <div>
                                        <MenuButton class="flex bg-gray-600 p-1 rounded-full items-center justify-center text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                            <span class="sr-only">Open user menu</span>
                                            <UserIcon class="h-6 w-6" aria-hidden="true" />
                                        </MenuButton>
                                    </div>
                                    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                                        <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
                                                <router-link :to="item.to" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">
                                                    {{ item.name }}
                                                </router-link>
                                            </MenuItem>
                                        </MenuItems>
                                    </transition>
                                </Menu>
                            </div>
                        </div>
                    </div>
                </header>

                <!-- Main content -->
                <div class="flex-1 flex items-stretch overflow-hidden">
                    <router-view />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue';

import {
    DatabaseIcon,
    HomeIcon,
    MenuAlt2Icon,
    PlusSmIcon,
    ClipboardIcon,
    XIcon,
} from '@heroicons/vue/outline';

import {
    SearchIcon,
    UserIcon,
} from '@heroicons/vue/solid';

import Login from '@/views/Login';
import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'App',
    components: {
        Dialog,
        DialogOverlay,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        TransitionChild,
        TransitionRoot,
        MenuAlt2Icon,
        UserIcon,
        PlusSmIcon,
        SearchIcon,
        ClipboardIcon,
        XIcon,
        Login,
    },
    data() {
        return {
            mobileMenuOpen: false,
            userNavigation: [
                {
                    name: 'Dein Profil',
                    to: '/profile',
                },
                {
                    name: 'Abmelden',
                    to: '/signout',
                },
            ],
        };
    },
    methods: {
        ...mapActions('products', ['listenAllergens', 'listenProducts', 'listenCategories', 'listenIngredientsSubjectToLabeling']),
        ...mapActions('menus', ['listenMenus']),
    },
    computed: {
        ...mapGetters(['user']),
        sidebarNavigation() {
            return [
                {
                    name: 'Home',
                    to: '/',
                    icon: HomeIcon,
                    current: false,
                },
                {
                    name: 'Speisekarten Generator',
                    to: '/menu-generator',
                    icon: ClipboardIcon,
                    current: false,
                },
                {
                    name: 'Datenbank',
                    to: '/db',
                    icon: DatabaseIcon,
                    current: false,
                },
            ];
        },
    },
    async beforeMount() {
        this.listenAllergens();
        this.listenCategories();
        this.listenProducts();
        this.listenIngredientsSubjectToLabeling();
        this.listenMenus();
    },
};
</script>
