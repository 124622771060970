import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import {collection, getFirestore, onSnapshot, query} from 'firebase/firestore';

// firebase init
const firebaseConfig = {
    apiKey: 'AIzaSyDWx2CkV8toK9pPWYbi9rI981ZR6nkGTtA',
    authDomain: 'mauros-cbd66.firebaseapp.com',
    databaseURL: 'https://mauros-cbd66-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'mauros-cbd66',
    storageBucket: 'mauros-cbd66.appspot.com',
    messagingSenderId: '120083783757',
    appId: '1:120083783757:web:8ea97e58e242c8f2c3fc04',
    measurementId: 'G-NBDTRTYMMV',
};


// utils
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const allergensCollection = collection(db, 'allergens');
const productsCollection = collection(db, 'products');
const productCategoriesCollection = collection(db, 'product_categories');
const ingredientsCollection = collection(db, 'ingredients_subject_to_labeling');
const menusCollection = collection(db, 'menus');

const createFirebaseListener = (collection, cb) => {
    const q = query(collection);
    return onSnapshot(q, (querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
            items.push({id: doc.id, ...doc.data()});
        });
        cb(items);
    });
};

export {
    auth,
    db,
    createFirebaseListener,
    // collections
    allergensCollection,
    ingredientsCollection,
    productsCollection,
    productCategoriesCollection,
    menusCollection,
};
