import {createRouter, createWebHashHistory} from 'vue-router';
import {getAuth, signOut} from 'firebase/auth';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
    },
    {
        path: '/menu-generator',
        name: 'MenuGenerator',
        component: () => import('@/views/MenuGenerator.vue'),
    },
    {
        path: '/db',
        name: 'Database',
        component: () => import('@/views/Database/Database.vue'),
        children: [
            {
                path: 'offers',
                name: 'Offers',
                component: () => import('@/views/Database/Offers/Offers.vue')
            },
            {
                path: 'offer/add',
                name: 'OfferAdd',
                component: () => import('@/views/Database/Offers/Add.vue')
            },
            {
                path: 'offer/edit/:id',
                name: 'OfferEdit',
                component: () => import('@/views/Database/Offers/Edit.vue')
            },
            {
                path: 'products',
                name: 'Products',
                component: () => import('@/views/Database/Products/Products.vue')
            },
            {
                path: 'product/add',
                name: 'ProductAdd',
                component: () => import('@/views/Database/Products/Add.vue')
            },
            {
                path: 'product/edit/:id',
                name: 'ProductEdit',
                component: () => import('@/views/Database/Products/Edit.vue')
            },
            {
                path: 'product_categories',
                name: 'ProductCategories',
                component: () => import('@/views/Database/ProductCategories/ProductCategories.vue')
            },
            {
                path: 'products/add',
                name: 'ProductsAdd',
                component: () => import('@/views/Database/Products/Add.vue')
            },
            {
                path: 'products/edit/:id',
                name: 'ProductsEdit',
                props: true,
                component: () => import('@/views/Database/Products/Edit.vue'),
            },
            {
                path: 'product_category/add',
                name: 'ProductCategoryAdd',
                component: () => import('@/views/Database/ProductCategories/Add.vue')
            },
            {
                path: 'product_categories/edit/:id',
                name: 'ProductCategoryEdit',
                props: true,
                component: () => import('@/views/Database/ProductCategories/Edit.vue')
            },
            {
                path: 'ingredients_subject_to_labeling',
                name: 'IngredientsSubjectToLabeling',
                component: () => import('@/views/Database/IngredientsSubjectToLabeling/IngredientsSubjectToLabeling.vue')
            },
            {
                path: 'ingredient_subject_to_labeling/add',
                name: 'IngredientSubjectToLabelingAdd',
                component: () => import('@/views/Database/IngredientsSubjectToLabeling/Add.vue')
            },
            {
                path: 'ingredient_subject_to_labeling/edit/:id',
                name: 'IngredientSubjectToLabelingEdit',
                component: () => import('@/views/Database/IngredientsSubjectToLabeling/Edit.vue')
            },
            {
                path: 'allergens',
                name: 'Allergens',
                component: () => import('@/views/Database/Allergens/Allergens.vue')
            },
            {
                path: 'allergen/add',
                name: 'AllergenAdd',
                component: () => import('@/views/Database/Allergens/Add.vue')
            },
            {
                path: 'allergen/edit/:id',
                name: 'AllergenEdit',
                component: () => import('@/views/Database/Allergens/Edit.vue')
            },
        ]
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () => import('@/views/Profile.vue'),
    },
    {
        path: '/signout',
        name: 'logout',
        beforeEnter(to, from, next) {
            const auth = getAuth();
            signOut(auth).then(() => {
                // Sign-out successful.
                next('/');
            }).catch((error) => {
                // An error happened.
            });
        },
        component: () => import('@/views/Home.vue'),
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
