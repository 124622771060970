import {createStore} from 'vuex';

import products from '@/store/modules/products';
import menus from '@/store/modules/menus';

const store = createStore({
    namespaced: true,
    state: {
        user: {
            loggedIn: true,
            data: null,
        },
    },
    getters: {
        user(state) {
            return state.user;
        },
    },
    mutations: {
        SET_LOGGED_IN(state, value) {
            state.user.loggedIn = value;
        },
        SET_USER(state, data) {
            state.user.data = data;
        },
    },
    actions: {
        fetchUser({commit}, user) {
            commit('SET_LOGGED_IN', user !== null);
            if (user) {
                commit('SET_USER', {
                    displayName: user.displayName,
                    email: user.email,
                });
            } else {
                commit('SET_USER', null);
            }
        },
    },
});
store.registerModule('products', products);
store.registerModule('menus', menus);

export default store;
